import * as React from "react"
import type { HeadFC } from "gatsby"
import Layout from "../components/layout"
import '../styles/global.css'

const pageStyles = {
  color: "#232129",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}

const IndexPage = () => {
  return (
    <main  className="bg-white" style={pageStyles}>
        <Layout>
            <section className="flex items-center h-auto py-20 justify-center bg-white">
                <div className="mx-auto max-w-[43rem]">
                    <div className="text-center">
                        <h1 className="text-[2.5rem] font-bold leading-[3rem] tracking-tight text-black">
                             Terms & Conditions
                        </h1>
                        <p className="mt-2 text-md leading-relaxed text-slate-400 text-left">
                             RELEVANT INFORMATION<br /><br />
                            It is a necessary requirement for the acquisition of the products offered on this site, that you read and accept the following Terms and Conditions that are written below. The use of our services as well as
                            <br /><br />
                            the purchase of our products will imply that you have read and accepted the Terms and Conditions of Use in this document. 
                            All the products that are offered by our website could be created, collected, sent or presented by a third web page and in such case they would be subject to their own Terms and Conditions. 
                            <br /><br />
                            In some cases, to purchase a product,registration by the user will be necessary, with the entry of reliable personal data and definition of a password.
                        </p>
                    </div>
                    <div className="mt-6 flex items-center justify-center gap-4">
                        <a href="" 
                        className="transform rounded-md bg-blue-600/95 px-5 py-3 font-medium 
                        text-white transition-colors hover:bg-yellow-400 hover:text-blue-600">Agree</a>
                    </div>
                </div>
            </section>
        </Layout>
    </main>     
  )
}

export default IndexPage

export const Head: HeadFC = () => <title>Terms & Conditions!</title>